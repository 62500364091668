import React from 'react';
import Select from 'react-select';
import { useThemeUI } from 'theme-ui';
import { useWindowSize } from '../utils';

export default function SelectBox({ options, onChange, defaultValue }) {
  const context = useThemeUI();
  const { theme } = context;
  const { colors, fonts } = theme;
  const size = useWindowSize();
  const controlWidth = size.width < 639 ? '100%' : 250;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? colors.background : colors.primary,
      fontFamily: fonts.body,
      opacity: state.isDisabled && 0.5,
      backgroundColor: state.isSelected ? colors.primary : colors.background,
    }),
    container: (provided, state) => ({
      ...provided,
      width: controlWidth,
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? `0 0 0 1px ${colors.primary}` : '',
      borderRadius: 'none',
      // none of react-select's styles are passed to <Control />
      width: controlWidth,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    menu: provided => ({
      ...provided,
      width: controlWidth,
      zIndex: 3000,
      backgroundColor: colors.background,
    }),
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      placeholder="select"
      onChange={onChange}
      defaultValue={defaultValue}
      isOptionDisabled={option => option.disabled}
      isSearchable={false}
    />
  );
}
