/** @jsx jsx */

import React, { useContext } from 'react';
import { jsx } from 'theme-ui';
import { StoreContext } from '../../context/StoreContext';

const AddToCart = ({ selectedVariant, quantity = 1, isAvailableForSale }) => {
  const { addProductToCart } = useContext(StoreContext);

  return (
    <button
      onClick={() => addProductToCart(selectedVariant.shopifyId, quantity)}
      sx={{
        variant: 'buttons.solid',
        width: ['100%', '250px'],
        ':disabled': {
          opacity: 0.5,
          cursor: 'not-allowed'
        }
      }}
      type="button"
      disabled={(selectedVariant === null) || isAvailableForSale === false}
    >
      {isAvailableForSale ? 'Add to Cart' : 'Sold Out'}
    </button>
  );
};

export default AddToCart;
