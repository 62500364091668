/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { jsx, Box, Flex, useThemeUI } from 'theme-ui';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ReactHtmlParser from 'react-html-parser';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SEO from '../components/seo/seo';
import Layout from '../components/Layout';
import AddToCart from '../components/Cart/AddToCart';
import SelectBox from '../components/SelectBox';
import client from '../client';
import './carousel.scss';
import BackArrow from '../images/chevron-left.inline.svg';
import NextArrow from '../images/chevron-right.inline.svg';
import { formatter } from '../utils';

const ProductDetailTemplate = ({ data, location }) => {
  const { shopifyProduct: product } = data;
  const {
    images: [firstImage],
    variants: [firstVariant],
    variants: allVariants,
    images: allImages,
  } = product;
  const [selectedVariant, setSelectedVariant] = useState(allVariants[0]);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const currentOptions = allVariants.map(variant => ({
    value: variant.shopifyId,
    label:
      variant.availableForSale === false
        ? `${variant.title} - SOLD OUT`
        : variant.title,
    disabled: !variant.availableForSale,
  }));

  const [isAvailableForSale, setIsAvailableForSale] = useState(
    product.availableForSale,
  );
  const [options, setOptions] = useState(currentOptions);

  useEffect(() => {
    if (allVariants.length === 1) {
      setSelectedVariant(allVariants[0]);
    }
    client.product.fetchByHandle(product.handle).then(fetchedProduct => {
      const newOptions = fetchedProduct.variants.map(variant => ({
        value: variant.id,
        label:
          variant.available === false
            ? `${variant.title} - SOLD OUT`
            : variant.title,
        disabled: !variant.available,
      }));
      setOptions(newOptions);
      setIsAvailableForSale(fetchedProduct.availableForSale);
    });
  }, []);

  const qtyOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => ({
    label: `${num}`,
    value: num,
  }));

  const context = useThemeUI();
  const { theme } = context;
  const { colors, fonts } = theme;

  const handleVariantChange = ({ value }) => {
    const variantSelected = allVariants.find(
      ({ shopifyId }) => shopifyId === value,
    );
    setSelectedVariant(variantSelected);
  };

  const handleQtyChange = ({ value }) => {
    setSelectedQuantity(value);
  };

  return (
    <Layout customSEO>
      <SEO
        title={product.title}
        isProduct
        product={product}
        pathname={location.pathname}
      />
      {/* <Container> */}
      <Link
        to="/"
        sx={{
          textDecoration: 'none',
          color: 'primary',
          textTransform: 'lowercase',
        }}
      >
        {`<- All Products`}
      </Link>
      <Flex sx={{ flexWrap: 'wrap', marginTop: 4 }}>
        <Box
          sx={{
            width: ['100%', '60%'],
            textAlign: 'center',
            pb: 4,
            color: 'text',
          }}
        >
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            totalSlides={allImages.length}
            lockOnWindowScroll
          >
            <Slider>
              {allImages.length > 0 &&
                allImages.map((image, i) => (
                  <div key={image.id}>
                    <Slide
                      index={i}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <GatsbyImage
                        image={image.localFile.childImageSharp.gatsbyImageData}
                        sx={{
                          opacity: !product.availableForSale && '0.5',
                        }}
                        loading="eager"
                        alt={product.title}
                      />
                    </Slide>
                  </div>
                ))}
            </Slider>
            <ButtonBack sx={{ display: ['none', 'none', 'block'] }}>
              <BackArrow
                sx={{ stroke: 'primary', fill: 'none', strokeWidth: 1 }}
              />
            </ButtonBack>
            <ButtonNext sx={{ display: ['none', 'none', 'block'] }}>
              <NextArrow
                sx={{ stroke: 'primary', fill: 'none', strokeWidth: 1 }}
              />
            </ButtonNext>
            <DotGroup sx={{ span: { backgroundColor: 'primary' } }} />
          </CarouselProvider>
        </Box>
        <Box
          sx={{
            width: ['100%', '40%'],
            // px: [null, 4],
            fontFamily: 'body',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: ['0px', '100px'],
          }}
        >
          <h1
            sx={{
              variant: 'styles.subHeader',
              color: 'text',
              fontSize: [3, 5],
              fontWeight: 'normal',
              marginBottom: [3, 5],
              textDecoration: product.availableForSale
                ? 'none'
                : 'line-through',
            }}
          >
            {product.tags.includes('pre-order') && 'pre-order - '}
            {product.title}
          </h1>
          <p
            sx={{
              variant: 'styles.subHeader',
              color: 'text',
              fontSize: [3, 5],
              fontWeight: 'normal',
              marginBottom: [3, 4],
              marginTop: 0,
              textDecoration: product.availableForSale
                ? 'none'
                : 'line-through',
            }}
          >
            {firstVariant.compareAtPriceV2 ? (
              <>
                <span sx={{ textDecoration: 'line-through' }}>
                  {formatter.format(firstVariant.compareAtPriceV2.amount)}
                </span>
                <span> - </span>
                <span>{formatter.format(selectedVariant.priceV2.amount)}</span>
              </>
            ) : (
              <span>{formatter.format(selectedVariant.priceV2.amount)}</span>
            )}
          </p>
          {allVariants.length > 1 && (
            <Flex
              sx={{
                mb: [3, 4],
              }}
            >
              <SelectBox
                options={options}
                placeholder="select"
                onChange={handleVariantChange}
              />
            </Flex>
          )}
          {/* <Flex sx={{ pb: 3 }}>
            <div>
              <p>Quantity:</p>
              <SelectBox
                options={qtyOptions}
                placeholder="quantity"
                defaultValue={{ label: '1', value: 1 }}
                onChange={handleQtyChange}
                width={100}
              />
            </div>
          </Flex> */}
          <AddToCart
            selectedVariant={selectedVariant}
            quantity={selectedQuantity}
            isAvailableForSale={isAvailableForSale}
          />
          {product.tags.includes('pre-order') && (
            <p sx={{ color: 'red' }}>
              Pre-orders payment will occur now, and will ship in 4 or so weeks
            </p>
          )}
        </Box>
      </Flex>
      <Flex>
        <div
          sx={{
            variant: 'styles.subHeader',
            color: 'text',
            textTransform: 'none',
            fontSize: [3, 5],
            fontWeight: 'normal',
            marginTop: [3, 4],
          }}
        >
          {ReactHtmlParser(product.descriptionHtml)}
        </div>
      </Flex>
      {/* </Container> */}
    </Layout>
  );
};

export default ProductDetailTemplate;

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      handle
      title
      productType
      availableForSale
      description
      descriptionHtml
      variants {
        shopifyId
        title
        priceV2 {
          amount
          currencyCode
        }
        compareAtPriceV2 {
          amount
        }
        sku
        availableForSale
      }
      tags
      images {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(maxWidth: 500, layout: FLUID, formats: [WEBP])
          }
        }
      }
    }
  }
`;
